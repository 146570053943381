@value defaultColor, lightBlack, buttonBackground from '../../common/colors.css';
@value laptopMediumMax, laptopSmallMax, laptopSmallestMax, largeWidth, largeHeight, mediumWidth, mediumHeight, smallWidth, largeTabWidth from '../../common/size.css';

.player {
  height: 100%;
  width: 100%;
  /* min-width: 84em; */
  /* background-color: lightBlack; */
  position: relative;
  /* z-index: 1; */
  overflow: hidden;
}

.video_frame {
  position: absolute;
  z-index: -1;
  width: auto;
  height:auto;
  overflow:hidden;
  min-width: 100%;
  min-height: 100%;
  /* min-width: 120em; */
}

.player_control {
  /* height: 100%; */
  width: 100%;
  z-index: 2;
}

.main {
  height:92vh;
  width: 100%;
  display:flex;
  align-items: center;
  overflow: hidden;
  /* min-height: 670px; */
}

.main_left {
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  width: 100%;
  /* flex:0 0 23em; */
  flex:0 0 20%;
  max-width: 22em;
  min-width: 18em;
  min-height: 40em;
  padding-top:3em;
  padding-bottom:10em;
  padding-left:0.5em;
  position: relative;

}

.main_center {
  height: 100%;
  flex: 1 1 auto;
}

.main_right {
  height: 100%;
  min-width:10em;
  /* overflow:hidden; */
  /* padding:2em 0.5em 2em 0; */
  /* flex:0 1 23em; */
  flex:0 1 20%;
  max-width: 23em;
}

.center {
  height: 100%;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.center_top {
  height: 55%;
  width: 20em;
  margin-top: 1em;
  display:flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 1em;
}

.center_bottom {
  height: 30%;
  width: 95%;
  /* padding-top: 5em; */
  /* margin-bottom: 1em; */
}

.toggle_display {
  transition: all 0.3s linear;
  /* height: 100%; */
}

.toggle_locking, .toggle_locking_sounds {
  color: rgba(180, 225, 221, 1);
  text-align: center;
  transform: translateY(-1.2em);
  font-size: 1.2rem;
}

.lock_icon {
  cursor: pointer;
  filter: drop-shadow(1px 1px 1px white);
  transition: ease 0.1s;
}

.lock_icon:hover {
  transform: scale(1.1);
}

.lock_icon:active {
  transform: scale(1);
  filter:none;
}

.sounds_circle {
  padding-left: 14em;
  transform: translateY(-3em);
  /* position:relative; */
}

.toggle_locking_sounds {
  position: absolute;
  left: 3em;
  top:50%;
}

 .screen_off {
  transform: translateY(13em);
}

.sound_off {
  transform: translateX(15em) translateY(-3em);
}

.modal_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(45deg, rgba(121, 121, 121, 0.1) 10%, rgba(120, 120, 120, 0.5) 50%);
  /* background-color: rgba(12, 12, 12, 0.637); */
  background-size: 3px 3px;
  z-index: 2;
}


.main_right::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 1em;
}

.main_right::-webkit-scrollbar-thumb{
  background-color: buttonBackground;
  border-radius: 5em;
}

.center_bottom::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 1em;
}

.center_bottom::-webkit-scrollbar-thumb{
  background-color: buttonBackground;
  border-radius: 5em;
}

@media screen and (max-width: largeWidth) {
  .sounds_circle {
    padding-left: 12em;
    transform: translateY(-1em);
  }
}

@media screen and (max-height: largeHeight) {

}

@media screen and (max-width: mediumWidth) {
  .center_bottom {
    height: 30%;
  }
  .sounds_circle {
    padding-left: 9em;
    /* transform: translateY(2em); */
  }
  .toggle_locking_sounds {
    left:1.5em;
  }
}

@media screen and (max-height: mediumHeight) {
  .main_left {
    padding-bottom: 5em;
  }
}

@media screen and (max-width: smallWidth) {
  .center_bottom {
    width: 30em;
    height: 30%;
    overflow:auto;
    display: flex;
    align-items: flex-end;
    padding-bottom: 1em;
    margin-bottom: 2em;
  }
  .main_left {
    flex: 0 0 25%;
  }
  .main_right {
    margin-top: 3em;
    /* flex: 0 1 5em; */
    flex: 0 1 25%;
    overflow: auto;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    height: 80%;
    padding-right: 1em;
  }
  .sounds_circle {
    padding: 0;
    width: 5em;
  }

}

@media screen and (max-width: largeTabWidth) {
  
}

