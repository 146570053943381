$defaultColor: rgba(255, 255, 255, 1);
$lightBlack: rgba(100, 100, 100, 1);

@mixin on-circle($item-count, $circle-size, $item-size) {  
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  border-radius: 50%;
  padding: 0; 
  list-style: none;
  
  > * {
    display: block;
    position: absolute;
    top:  50%; 
    left: 50%;
    margin: -($item-size / 2);
    width:  $item-size;
    height: $item-size;
    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 0 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
        &.ball_sound_selected {
          transform: rotate($rot * 1deg) translate($circle-size / 1.95) rotate($rot * -1deg) scale(1.15);
          &:hover {
            transform: rotate($rot * 1deg) translate($circle-size / 1.95) rotate($rot * -1deg) scale(1.2);
          }
        }
        &:hover {
          transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg) scale(1.2);
        }
      }
      $rot: $rot + $angle;
    }
  }
  
}

.sound-circle-container {
  @include on-circle($item-count: 32, $circle-size: 60em, $item-size: 4.5em); 
  margin: 0em auto 0;
  // margin-left: 15em ;
  // border: solid 5px tomato;
  .ball_sound_container{
    transition: all 0.2s linear;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius:50%;
    border: 2px solid $defaultColor;
    overflow: hidden;
    padding: 0.2em;
  }

  img { 
    display: block; 
    max-width: 100%; 
    filter: grayscale(100%);
    transition: .15s;
    
    &:hover,
    &:active {
      filter: grayscale(0);
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}

.sound-list-container {
  .ball_sound_container{
    transition: all 0.2s linear;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius:50%;
    border: 2px solid $defaultColor;
    overflow: hidden;
    padding: 0.2em;
    width: 4em;
    height: 4em;
    margin-bottom: 0.8em;
  }
}


.ball_sound_selected {
  background-color: rgb(66, 116, 144);
  // width: 5em;
  // height: 5em;
}

@media screen and (max-width: 1650px) {
  .sound-circle-container {
    @include on-circle($item-count: 32, $circle-size: 55em, $item-size: 4.5em); 
  }
}

@media screen and (max-width: 1400px) {
  .sound-circle-container {
    @include on-circle($item-count: 32, $circle-size: 50em, $item-size: 4.2em); 
  }
}
