@value defaultColor, dropShadowFilter, lightBlack, cardBackground, cardBoxShadow, hoverBoxShadow, activeClick, buttonBoxShadow, buttonBackground, hoverButtonShadow, defaultRed, defaultPink from '../../common/colors.css';

.mood_card {
  display: flex;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin: 1em 0;
  border-radius: 10px;
  background-color: cardBackground;
  box-shadow: cardBoxShadow;
  width: 100%;
  /* min-width: 15em; */
}

.mood_card:hover {
  transform: scale(1.02);
  box-shadow: hoverBoxShadow;
  transition: all 0.1s linear;
}

.mood_card:hover .mood_control_btn {
  transform: scale(1.03);
  transition: all 0.1s linear;
}

.mood_screen_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5em;
}

.mood_screen {
  /* background-color: defaultColor; */
  width: 4em;
  height: 4em;
  border-radius: 50%;
  overflow:hidden;
  filter: grayscale(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* border: 2px solid defaultColor; */
}

.mood_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.3em 0;
  overflow: hidden;
}

.mood_sounds_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 8em;
  align-items: flex-start;
}

.mood_title {
  color: whitesmoke;
  text-align: right;
  width: 90%;
  padding: 0.2em 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 2px solid defaultColor;
  margin-left: 1em;
  letter-spacing: 0.1em;
  filter: dropShadowFilter;
}

.mood_sounds {
  display: flex;
  /* width: 100%; */
  justify-content: flex-start;
  padding-left: 1.2em;
  overflow: hidden;
  filter: dropShadowFilter;
}

.sound_ball {
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  /* background-color: defaultColor; */
  margin: 0 0.3em;
  border: 1px solid whitesmoke;
}

.mood_control {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5em 0;
}

.mood_control_btn {
  margin: 0 0.3em;
  margin-top: 1em;
  border-radius: 20%;
  width: 1.8em;
  height: 1.8em;
  background-color: buttonBackground;
  color: rgb(81 78 67);
  box-shadow: buttonBoxShadow;
  border: 0;
  cursor: pointer;
  padding: 0;
  font-size: 0.85rem;
}

.mood_control_btn:active {
  box-shadow: activeClick;
}

.play:hover {
  color: defaultPink;
}

.delete:hover {
  color: defaultRed;
}

@media screen and (max-width: 1550px) {
  .mood_card {
    /* min-width: 14em; */
  }
  .mood_screen {
    width: 3.5em;
    height: 3.5em;
  }
}