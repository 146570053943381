$defaultColor: rgba(255, 255, 255, 1);

@mixin on-circle($item-count, $circle-size, $item-size-width, $item-size-height) {  

    position: relative;
    width:  $circle-size;
    height: $circle-size;
    border-radius: 50%;
    padding: 0; 
    list-style: none;
    
    > * {
      display: block;
      position: absolute;
      top:  50%; 
      left: 50%;
      margin: -($item-size-width / 2);
      width:  $item-size-width;
      height: $item-size-height;
    
      $angle: (360 / $item-count);
      $rot: 0;
  
      @for $i from 1 through $item-count {
        &:nth-of-type(#{$i}) {
          transform: rotate($rot * 1deg) translate($circle-size / 2) rotate(89deg);
          &.screen_ball_selected {
            transform: rotate($rot * 1deg) translate($circle-size / 1.9) rotate(89deg) scale(1.1);
            &:hover {
              transform: rotate($rot * 1deg) translate($circle-size / 1.9) rotate(89deg) scale(1.2);
            }
          }
          &:hover {
            transform: rotate($rot * 1deg) translate($circle-size / 2) rotate(89deg) scale(1.2);
          }
        }
  
        $rot: $rot + $angle;
      }
    }
  
}

.screen-circle-container {
  @include on-circle($item-count: 27, $circle-size: 60em, $item-size-width: 5em, $item-size-height: 7em); 
  margin: 5em auto 0;
  // border: solid 5px tomato;

  .screen_ball_container {
    display:inline-flex;
    transition: all 0.1s linear;
    // border:1px solid $defaultColor;
    border-radius: 10%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 10px rgba(150, 150, 150, 0.5)  ; 
    // padding: 0.1em;
  }

  img { 
    display: block; 
    max-width: 100%; 
    filter: grayscale(50%);
    transition: .15s;
    
    &:hover,
    &:active {
      filter: grayscale(0);
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}

.screen-list-container {
  display: flex;
  overflow-x: auto;
  .screen_ball_container {
    display:inline-flex;
    transition: all 0.1s linear;
    // border:1px solid $defaultColor;
    border-radius: 10%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 10px rgba(150, 150, 150, 0.5)  ; 
    width: 4.5em;
    height: 6em;
    margin-right: 1em;
    // padding: 0.1em;
  }
}

.screen_ball_selected:active {
  background-color:rgb(183, 246, 249);
  // margin: 0em;
  // width: 6em;
  // height: 9em;
}

.screen_ball_selected img {
  filter: grayscale(0);
  // transform: scale(1.2)
}

@media screen and (max-width: 1650px) {
  .screen-circle-container {
    // transform: rotate(0);
    // overflow: scroll;
    @include on-circle($item-count: 27, $circle-size: 50em, $item-size-width: 5em, $item-size-height: 7em); 
    // margin: 5em auto;
  }
}

@media screen and (max-width: 1400px) {
  .screen-circle-container {
    // transform: rotate(0);
    // overflow: scroll;
    @include on-circle($item-count: 27, $circle-size: 40em, $item-size-width: 4em, $item-size-height: 6em); 
    // margin: 5em auto;
  }
}
