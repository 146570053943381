@value defaultColor, dropShadowFilter, lightBlack, cardBackground, cardBoxShadow, hoverBoxShadow, defaultRed from '../../common/colors.css';

.card {
  display:flex;
  align-items: center;
  border-radius: 10px;
  padding: 0.3em 1em;
  margin-bottom: 0.5em;
  background-color: cardBackground;
  box-shadow: cardBoxShadow;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: hoverBoxShadow;
  transition: all 0.05s ease;
}

.sound_ball {
  width:3.5em;
  /* background-color: defaultColor; */
  border-radius: 50%;
  margin: 0.5em 0;
  border: 1.5px solid whitesmoke;
  filter: dropShadowFilter;
}

.volume_display {
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 0.5em;
}

.volume_point {
  width: 0.7em;
  height: 0.7em;
  border: 1.5px solid defaultColor;
  border-radius: 50%;
  margin:0 0.3em;
  
}

.volume_point.volume_active {
  background-color: defaultColor;
  filter: dropShadowFilter;
}

.volume_number {
  color: defaultColor;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 1.5rem;
  width: 0.5em;
  filter: dropShadowFilter;
}

.volume_btn {
  display:flex;
  flex-direction: column;
  justify-content: center;
  filter: dropShadowFilter;
}

.volume_up {
  width: 1em;
  margin-bottom:0.5em;
  cursor: pointer;
}

.volume_down {
  width:1em;
  transform: rotate(180deg);
  cursor: pointer;
}

.volume_up:hover {
  transform: scale(1.25);
}

.volume_down:hover {
  transform: scale(1.25) rotate(180deg);
}

.volume_up:active {
  transform: scale(0.9);
  transition: all 0.2s linear;
}

.volume_down:active {
  transform: scale(0.9) rotate(180deg);
  transition: all 0.2s linear;
}

.remove_btn {
  border: 0;
  outline: 0;
  background: none;
  font-size: 1.2rem;
  color: defaultColor;
  cursor: pointer;
  filter: dropShadowFilter;
}

.remove_btn:hover {
  color: defaultRed;
}

.remove_btn:active {
  transform: scale(0.8);
}