@value defaultColor, dropShadowFilter from '../../common/colors.css';

.sound_ball {
  width:100%;
  filter: dropShadowFilter;
}

.sound_ball_img {
  width:100%;
  padding:0.5em;
}
