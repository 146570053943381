@value lightBlack from './common/colors.css';

.app {
  height: 100vh;
  width: 100vw;
  display:flex;
  flex-direction: column;
  align-items: center;
}

