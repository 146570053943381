@value defaultColor, lightBlack from '../../common/colors.css';

.container {
  width: 100%;
  height: 100%;
  background-color: #F8F5F1;
  display:flex;
  justify-content: center;
  align-items: center;
  background: url('https://airsounds-media.s3.us-east-2.amazonaws.com/background_auth.png');
  background-size: contain;
}

.logo {
  position: absolute;
  width: 8em;
  top: 2em;
  right: 2em;
  cursor: pointer;
}

.frame {
  width: 50%;
  min-width: 50em;
  height: 60%;
  display:flex;
  position:relative;
}

.login_container,
.signup_container { 
  width:50%;
  height:100%;
  display:flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-end;
  padding-bottom: 5em;
}

.login_input,
.signup_input {
  display:flex;
  flex-direction: column;
  padding: 5em 5em ;
  padding-bottom: 1em;
  align-items: center;
}

.login_input .submit_button {
  margin-top:3em;
}

.signup_input {
  
}

.label {
  color: darkgray;
  width:100%;
  margin:0em 0em;
  font-size:0.8rem;
}

.input {
  /* padding-top:0.2em; */
  padding-left:0.5em;
  width:100%;
  border: 0;
  outline: 0;
  font-size: 1rem;
  color:lightBlack;
}

.label::after {
  content: '';
  display: block;
  width: 90%;
  height:1px;
  background-color: lightgray;
  margin-bottom:1em;
}

.submit_button {
  width: 7em;
  margin-top:2em;
  padding:0.3em 0;
  font-weight: bold;
  border: 0;
  border-radius: 0.2em;
  background-color:lightBlack;
  color: whitesmoke;
  letter-spacing: 0.2em;
  font-size: 1rem;
  cursor: pointer;
}

/* .submit_button:hover {
  background-color: #AD310B;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
} */

.auth_container {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login_text{
  padding:0.2em 0;
  text-align: center;
  color: lightBlack;
  font-size: 0.8rem;
}

.login_link {
  width: 10em;
  margin-bottom:0.3em;
  padding:0.2em 0;
  text-align: center;
  cursor: pointer;
  color:lightBlack;
  font-size: 0.9rem;
  transition: ease 0.1s;
}

.login_link:hover {
  transform: scale(1.03);
  color:rgb(249, 185, 25)
}

.login_link:active {
  transform: scale(0.97)
}

.auth_login {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth_divider {
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.text_or {
  width: 2em;
  z-index: 1;
  background-color: white;
  color: lightBlack;
}

.auth_divider::after {
  content: '';
  display: block;
  width: 90%;
  height:1px;
  background-color: lightgray;
  transform: translateY(-1.5em);
}

.auth_btn_container {
  display:flex;
  justify-content: center;
}

.auth_btn {
  width: 2.5em;
  height: 2.5em;
  margin:0 1em;
  padding:0;
  border: none;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s linear;
}

.auth_img {
  width: 100%;
}

.auth_btn:hover {
  transform: scale(1.1);
}

.auth_google_logo {
  padding: 0.1em;
}

.banner {
  position: absolute;
  height:100%;
  width: 50%;
  background-color: lightBlack;
  z-index:2;
}

.banner_button {
  position: absolute;
  width: 9em;
  height: 2em;
  margin: auto;
  left: 0; right: 0;
  bottom:5em;
  background:none;
  border: 1px solid rgb(249, 185, 25);
  border-radius: 5px;
  font-size: 1rem;
  letter-spacing: 0.12em;
  font-weight: bold;
  color:rgb(249, 185, 25);
  cursor: pointer;
  padding: 0;
  transition: ease 0.1s;
  text-shadow: 0.5px 1px rgb(205, 205, 205);
  box-shadow: 1px 1px 0.5px rgb(170, 170, 170);
}

.banner_button:hover {
  transform: scale(1.1);
  text-shadow: 0.5px 1px rgb(205, 205, 205);
  border:none;
  background-color: lightBlack;
}

.banner_button:active {
  transform: scale(1.05)
}

.banner.switch_1 {
  transform: translateX(100%);
  background-color: rgb(89 118 122);;
  transition: all 0.7s linear;
}

.banner.switch_2 {
  transform: translateX(0%);
  background-color: rgb(91 141 196);
  transition: all 0.7s linear;
}

.login_banner,
.signup_banner {
  width: 70%;
  opacity: 0;
  transition: opacity 1s ease 0.4s;
  position: absolute;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
}


.login_banner.active {
  opacity: 1;
}

.signup_banner.active{
  opacity: 1;
}




