@value defaultColor, dropShadowFilter from '../../common/colors.css';
@value smallWidth from '../../common/size.css';

.header {
  border-bottom: 1px solid defaultColor;
  width: 100%;
  height: 100%;
  display:flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: defaultColor;
  min-width: 60em;
}

.logo {
  flex: 0 0 20%;
  padding-left: 8em;
  cursor: pointer;
}

.logo_img {
  width: 8em;
  filter: dropShadowFilter;
}

.title {
  font-size:2rem;
  letter-spacing: 0.4rem;
  font-weight:bold;
  text-align: center;
  flex: 1 0 auto;
  padding-top:0.4em;
  filter: dropShadowFilter;
}

.control_container {
  display:flex;
  flex: 0 1 20%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8em;
  padding-top:0.4em;
}

.control_login {
  width: 7em;
  height:1.5em;
  border-radius: 1em;
  border: 0.15em solid defaultColor;
  text-align: center;
  padding:0.1em 1.5em;
  line-height: 0.8;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  cursor: pointer;
}


@media screen and (max-width: smallWidth) {
  .logo {
    padding-left: 3em;
    flex: 0
  }
  .control_container {
    padding-right: 3em;
    flex: 0 1 10%;
  }
}