@value defaultColor, dropShadowFilter, containerBack, containerBoxShadow, lightBlack, buttonBackground, defaultRed, barColor, defaultPink, defaultRed from '../../common/colors.css';
@value mediumHeight from '../../common/size.css';

.console_container {
  height: 100%;
  width: 100%;
  min-height: 25em;
  overflow: hidden;
  padding: 0.2em 0.2em;
  padding-bottom: 0;
}

.console {
  height: 92%;
  width: 100%;
  overflow-y: auto;
  padding: 0.5em 0.7em;
  background-color: containerBack;
  border-radius: 0.5em;
  padding-top: 1.5em;
  transition: transform 0.5s linear;
  box-shadow: containerBoxShadow;
}

.console::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 1em;
}

.console::-webkit-scrollbar-thumb{
  background-color: buttonBackground;
  border-radius: 5em;
}

.console.closed {
  /* transform:translateY(-27em); */
  transform:translateY(-110%);
}

.console_controller {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.console_contoller_box {
  position: absolute;
  display:flex;
  justify-content: space-evenly;
  /* width: 50%; */
  background-color: containerBack;
  border-radius: 10em;
  padding:0.3em 0.3em;
  transform: translateY(-50%);
}

.console_control_btn {
  margin:0 0.3em;
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  background-color: buttonBackground;
  border:0;
  cursor: pointer;
  color: rgb(81 78 67);
  filter: dropShadowFilter;
}

.console_control_btn:hover {
  transform: scale(1.05);
  transition: all 0.1s linear;
  color: defaultPink;
}

.console_control_btn:active {
  transform: scale(0.95);
  transition: all 0.1s linear;
}

.delete:hover {
  color: defaultRed;
}

.console_top {
  position: relative;
  width: 100%;
  height: 22%;
  margin-bottom: 0.3em;
}

.selected_screen {
  margin:0em 0.5em;
  background-color: defaultColor;
  border-radius: 50%;
  width: 4.5em;
  height: 4.5em;
  position: relative;
  transition: opacity 0.5s linear;
  z-index:1;
  overflow:hidden;
  filter: grayscale(50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear_screen_btn {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border:none;
  position: absolute;
  top:0.2em;
  right:0.15em;
  color:defaultColor;
  outline: none;
  font-size: 0.75rem;
  background: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  font-size:2rem;
}

.selected_screen:hover {
  opacity: 0.7;
}


.selected_screen:hover .clear_screen_btn {
  visibility: visible;
  opacity: 1;
  z-index:2;
  color: defaultRed;
}

.mood_info {
  position: absolute;
  top: 0.5em;
  right: 0em;
  width: 12em;
}

.mood_info p {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-top: 0.5em;
  color: defaultColor;
  font-style: italic;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.03em;
}

.mood_info input {
  width: 7em;
  font-size: 1rem;
  outline: none;
  border: none;
  background: none;
  color: defaultColor;
  text-align:start;
  margin-left:0.5em;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.mood_info input::placeholder {
  color: defaultColor;
  opacity: 0.8;
  font-style: italic;
}

.console_toggle {
  margin-top:0.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s linear;
  filter: dropShadowFilter;
}

.console_toggle.closed {
  transform: translateY(calc(-44vh));
}

.console_bar {
  height:0.25em;
  width: 15em;
  outline:0;
  background-color: barColor;
  margin: 0.5em 0;
  border-radius: 15px;
}

.toggle_btn {
  width: 1.2em;
  transition: all 0.2s linear;
}

.toggle_btn:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.toggle_btn.closed {
  transform: rotate(-180deg)
}

.toggle_btn.closed:hover {
  transform: rotate(-180deg) scale(1.3)
}

@media screen  and (max-height: mediumHeight) {
  .console_toggle.closed {
    transform: translateY(calc(-22em));
  }
}
