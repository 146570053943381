.intro_container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: no-repeat center center/cover;
}

/* .intro_video {
  width: 100vw;
  min-width: 1250px;
  z-index: 1;
} */

.intro_video {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(45deg, rgba(0,0,0,.2) 80%, rgba(0,0,0,.1) 50%);
  /* background-color: rgba(12, 12, 12, 0.637); */
  background-size: 3px 3px;
  z-index: 2;
}

.intro_logo {
  width: 8em;
}

.intro_contents {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index:3;
  display: flex;
  flex-direction: column;
  padding:3em 5em;
}

.intro_logo {
  color: #fff;
}

.intro_main {
  color: #fff;
  margin-top: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 45em;
}

.intro_center h3 {
  font-size: 2.5rem;
  margin:0;
  margin-bottom: 0.2em;
  white-space: nowrap;
}

.intro_center h2 {
  font-size: 3rem;
  margin:0;
  margin-bottom: 1em;
  white-space: nowrap;
}

.intro_buttons {
  display: flex;
  justify-content: center;
}

.intro_buttons button {
  margin:0 0.8em;
  color: #fff;
  background: none;
  border: 2px solid #fff;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  border-radius: 10em;
  padding: 0.2em 1em;
  cursor: pointer;
}